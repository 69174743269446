/* global rp$, window */
'use strict';

const get = require('lodash/get');

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');
	const FORWARD_SLASH = '/';

	/****************************************************************************
	 * Load Javascript for Login/Register Module - handles redirect query strings
	 * --------------------------------------------------------------------------
	 * @section login_register_redirect_js
	 ****************************************************************************/
	(function LoginRegisterRedirectHandler() {
		let $loginLink,
			$registerLink;

		$onReady({
			rp$, window,
			label: 'login_register_redirect',
			fn: function () {
					$loginLink = rp$('.login-redirect-link');
					$registerLink = rp$('.register-redirect-link');

					const $baseLoginHref = get($loginLink, '[0].href');
					const $baseRegisterHref = get($registerLink, '[0].href');
					if ($baseLoginHref && $baseRegisterHref) {
						const currentUrlPath = get(window, 'location.pathname');
						const currentUrlQueryString = get(window, 'location.search');

						// Append redirect query string [Currently FCA Only]
						const redirectQueryString = `?tg=REVPCDRAPOMREVP&p=${encodeURIComponent(`${currentUrlPath}${currentUrlQueryString}`)}`;

						// Login
						let queryStringPrefix = $baseLoginHref.endsWith(FORWARD_SLASH) ? '' : FORWARD_SLASH;
						const $newLoginHref = `${$baseLoginHref}${queryStringPrefix}${redirectQueryString}`;

						// Register
						queryStringPrefix = $baseRegisterHref.endsWith(FORWARD_SLASH) ? '' : FORWARD_SLASH;
						const $newRegisterHref = `${$baseRegisterHref}${queryStringPrefix}${redirectQueryString}`;

						// Set new hrefs
						$loginLink[0].href = $newLoginHref;
						$registerLink[0].href = $newRegisterHref;
					}
			},
		});
	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}